export const homeObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "",
    headline: "Make sure to check back again later.",
    description: "",
    buttonLabel: "",
    imgStart: "",
    img: "/images/news.jpg",
    alt: "",
    target: "",
    buttonVisible: false,
    shortDesc: true
};

