export const homeObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "With over 10 years of experience..",
    headline: "",
    description: "we are experienced in cross-country pipelines as well as inter site pipelines related to liquid and gas product transfer pipelines, steam, and industrial water pipelines.",
    buttonLabel: "",
    imgStart: "",
    img: "/images/industries/pipelines2.jpg",
    alt: "",
    target: "",
    buttonVisible: false
};

