export const homeObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "We have over 5 years of experience...",
    headline: "",
    description: "in a multitude of software projects. This includes varieties of:\n-web development\n-data management\n-user-interfaced applications",
    buttonLabel: "",
    imgStart: "",
    img: "/images/industries/software3.jpg",
    alt: "",
    target: "",
    buttonVisible: false
};
