export const homeObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "We have over 10 years of experience in...",
    headline: "",
    description: "upstream oil sands projects related to:\n-surface mining involving crushers\n-surge bins\n-slurry preparation plants\n-conveyors\n-pumping stations\n-pipelines",
    buttonLabel: "",
    imgStart: "",
    img: "/images/industries/mining2.jpg",
    alt: "",
    target: "",
    buttonVisible: false
};
